import React, { useEffect, useState, useMemo } from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Auth, API } from 'aws-amplify';
import { Modal, Button, } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';

const Register = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [target, setTarget] = useState(false);
  const [otpCode, setOTPCode] = useState();
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClick = async () => {
    setTarget(false)
    console.log('handleClick sign in', email, password);
    Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,          // optional
        name: name
      }
    }).catch((error) => {
      setTarget(false)
      console.log(error);
      alert("Please Enter Correct email or password!");
    });
    setTimeout(() => {
      setShow(true)
    }, 2000);
  }
  const verifyOTPCode = async e => {
    e.preventDefault();
    Auth.confirmSignUp(email, otpCode).then(user => {
      history.push('/admin/tables');
    }).catch((error) => {
      setTarget(false)
      console.log(error);
      alert("Please Enter Correct OTP!");
    })
  }
  const handleOTPCode = (event) => {
    setOTPCode(event.target.value);
  };
  return (
    <>
      <Modal show={show}>
        {/* <Modal.Header closeButton>
          </Modal.Header> */}
        <Modal.Body>
          {/* <div className="form-group fv-plugins-icon-container"> */}
          <Input
            placeholder="Enter OTP :"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="OTP"
            value={otpCode}
            onChange={handleOTPCode}
          />
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
              </Button>
          <Button variant="primary" type="submit" onClick={verifyOTPCode}>
            Verify Otp
              </Button>
        </Modal.Footer>
      </Modal>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up with credentials</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Name" type="text"
                    onChange={(e) => setName(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div> */}
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="button" onClick={(e) => { e.preventDefault(); handleClick() }}>
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
